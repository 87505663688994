@import "/src/styles/mixin";

.content {
  height: 50vh;
  display: flex;
  align-items: center;
  gap: 24px;
  flex: 0 1 auto;
  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    height: 100%;
    padding: 0 0 40px;
    gap: 24px;
    flex-direction: column-reverse;
  }
}

.subtitle {
  margin-bottom: 32px;
  font-size: 48px;
  line-height: 65px;
  font-weight: 900;
  span {
    color: var(--primary-color);
  }
  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    @include font18;
    margin-bottom: 12px;
    text-align: center;
  }
}

.text {
  @include font18;
  font-weight: 500;
  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    @include font18;
    font-weight: 500;
    text-align: center;
  }
}

.link {
  @include font18;
  font-weight: 500;
  color: var(--primary-color);
}

.img {
  &Wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    max-width: 640px;
    height: 100%;
    background-repeat: no-repeat;
    background-image: url("../../assets/images/404.webp");
    background-position: center;
    background-size: contain;
    @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
      height: 250px;
    }
  }
}

.title {
  font-size: 96px;
  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    font-size: 48px;
  }
}
